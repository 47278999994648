import { Injectable } from '@angular/core';
import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { StorageService } from './storage.service';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userManager: PublicClientApplication;

  private tokenRequest: unknown;
  private silentProfileRequest: SilentRequest;
  private profileRedirectRequest: RedirectRequest;

  private defaultAlias: string;
  private account: AccountInfo;
  private pageReloadNeeded: boolean;

  constructor(private storageService: StorageService,private httpClient: HttpClient) {
    this.tokenRequest = {
      scopes: [`${environment.baseUrl}/user_impersonation`],
    };

    this.silentProfileRequest = {
      scopes: [`${environment.baseUrl}/user_impersonation`],
      account: null,
      forceRefresh: false,
    };

    this.profileRedirectRequest = {
      scopes: [`${environment.baseUrl}/user_impersonation`],
      redirectStartPage: window.location.href,
    };

    this.userManager = new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: `https://login.microsoftonline.com/${environment.tenantId}/`,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.log(message);
                return;
              case LogLevel.Verbose:
                console.log(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
      },
    });

    this.defaultAlias = '';
    const those = this;

    this.userManager
        .handleRedirectPromise()
        .then((response) => {
          this.account = response ? response.account : those.getAccount();
          if (response && (response.accessToken || response.idToken)) {
            this.storageService.set('accessToken', response.idToken || response.accessToken);
            this.storageService.set('accessTokenExpiration', response?.expiresOn?.toString());

            if (response.accessToken) {
              if (this.pageReloadNeeded) {
                window.location.reload();
              }
            }
          }
        })
        .catch(console.error);
  }

  private getAccount(): any {
    const currentAccounts = this.userManager.getAllAccounts();
    if (currentAccounts === null) {
      console.log('No accounts detected');
      return null;
    }

    if (currentAccounts.length > 1) {
      console.warn('Multiple accounts detected, need to add choose account code.');
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }

  async login(): Promise<void> {
    try {
      await this.userManager.loginRedirect(this.tokenRequest as any);
    } catch (e) {
      if (e instanceof BrowserAuthError && e.errorCode === 'interaction_in_progress') {
        console.log('swallowed BrowserAuthError(interaction_in_progress)');
      } else {
        throw e;
      }
    }
  }

  // @ts-ignore
  async authorizeUser(): Promise<boolean> {
    const account = this.account || this.getAccount();
    await this.getUserProfileImage();
    if (account) {
      try {
        this.silentProfileRequest.account = account;
        const response: AuthenticationResult = await this.userManager.acquireTokenSilent(this.silentProfileRequest);
        this.setTokenData(response);
        return true;
      } catch (e) {
        console.log('silent token acquisition fails.');
        if (e instanceof InteractionRequiredAuthError) {
          console.log('acquiring token using redirect');
          try {
            const response: any = await this.userManager.acquireTokenRedirect(this.profileRedirectRequest);
            this.setTokenData(response);

            return true;
          } catch (err) {
            console.error(err);
            console.log('getUser(), returning null');

            return false;
          }
        } else if (/*e instanceof ClientAuthError && */ e.errorCode === 'no_tokens_found') {
          console.log('swallowed ClientAuthError(\'no_tokens_found\')');
          console.log('acquiring token using redirect');
          try {
            const response: any = await this.userManager.acquireTokenRedirect(this.profileRedirectRequest);
            this.setTokenData(response);

            return true;
          } catch (err) {
            console.error(err);
            console.log('getUser(), returning null');

            return false;
          }
        } else {
          console.error(e);
          console.log('getUser(), return null.');
        }
      }
    } else {
      console.log('getUser(), account = null');
      this.pageReloadNeeded = true;
    }
  }

  logout(): void {
    this.storageService.set('logoutRedirect', 'true');
    window.location.assign('/');
  }

  doLogout(): void {
    this.userManager.logoutRedirect();
  }

  get userInfo(): object | null {
    return this.userManager.getActiveAccount();
  }

  get accessToken(): string | null {
    return this.storageService.get('accessToken');
  }

  get isTokenExpired(): boolean {
    const now = Date.now();
    const tokenExpiration = parseInt(this.storageService.get('accessTokenExpiration'), 10);

    return now > tokenExpiration;
  }

  private setTokenData(response: any): void {
    this.storageService.set('accessTokenExpiration', response.expiresOn.getTime().toString());
    this.storageService.set('accessToken', response.accessToken);
  }

  async getAccessToken(): Promise<string | null> {
    const account = this.getAccount();
  
    if (!account) {
      throw new Error('No active account found');
    }
  
    const silentRequest = {
      scopes: ['User.Read'],
      account:account
    };
  
    try {
      const response = await this.userManager.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
   getUserProfileImage() {
    const token=this.getAccessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.httpClient.get
    this.httpClient.get('https://graph.microsoft.com/v1.0/me/photo/$value', { headers, responseType: 'blob' })
      .subscribe(image => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          // this will convert blob to base64 img
          let base64Image = reader.result;
          sessionStorage.setItem('profileImage', base64Image as string);
        }, false);
        if (image) {
          reader.readAsDataURL(image);
        }
      });
    }
}
