<dds-header
  [name]="'QR code'"
  [isResponsive]="true"
  *ngIf="user"
>
  <dds-logo logo routerLink="/"></dds-logo>

  <ng-container navigation>
    <dds-button
      role="menuitem"
      kind="primaryLoud"
      [theme]="'/dashboard' | buttonActive: currentRoute"
      [routerLink]="'/dashboard'"
    >Dashboard
    </dds-button>
    <dds-button
      role="menuitem"
      kind="primaryLoud"
      *ngIf="isGroupManagement || isSuperVisor"
      [theme]="'/my-requests' | buttonActive: currentRoute"
      [routerLink]="'/my-requests'"
    >My Requests
    </dds-button>
    <!--    <dds-button-->
    <!--      role="menuitem"-->
    <!--      kind="primaryLoud"-->
    <!--      [theme]="'/alias' | buttonActive: currentRoute"-->
    <!--      [routerLink]="'/alias'"-->
    <!--    >Alias-->
    <!--    </dds-button>-->
    <dds-dropdown
      label="Configuration"
      [theme]="'/configuration, /qr' | buttonActive: currentRoute"
      *ngIf="isSuperAdmin">
      <dds-dropdown-item
        value="Spaces"
        routerLink="/configuration"
      >
        Spaces
      </dds-dropdown-item>
      <dds-dropdown-item
        value="Area types"
        routerLink="/qr"
      >
        Area Types
      </dds-dropdown-item>
    </dds-dropdown>
    <dds-dropdown
      label="Users"
      [theme]="'/admin-users, /group' | buttonActive: currentRoute : '/group/[0-9]+/[A-z]+'"
      *ngIf="isSuperAdmin || isGroupManagement">
      <dds-dropdown-item
        value="Admins"
        routerLink="/admin-users"
        *ngIf="isSuperAdmin"
      >
        Admins
      </dds-dropdown-item>
      <dds-dropdown-item
        value="Group management"
        routerLink="/group"
        *ngIf="isGroupManagement"
      >
        Group management
      </dds-dropdown-item>
    </dds-dropdown>
  </ng-container>
  
  <ng-container profile>
    <dds-profile *ngIf="showImage"
      [username]="user?.firstName + ' ' + user?.lastName"
      [photoUrl]="profileImage"
    >    
      <dds-profile-item (click)="openCookies()">
        Cookies
      </dds-profile-item>
      <dds-profile-item (click)="onCookieSettings()">
        Cookie Settings
      </dds-profile-item>
      <dds-profile-item (click)="onLogout()">
        Log out
      </dds-profile-item>
    </dds-profile>
    <dds-profile *ngIf="!showImage"
      [username]="user?.firstName + ' ' + user?.lastName"
      [nameLetter]="initials"
    >    
      <dds-profile-item (click)="openCookies()">
        Cookies
      </dds-profile-item>
      <dds-profile-item (click)="onCookieSettings()">
        Cookie Settings
      </dds-profile-item>
      <dds-profile-item (click)="onLogout()">
        Log out
      </dds-profile-item>
    </dds-profile>
  </ng-container>
</dds-header>
