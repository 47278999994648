import { Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AccountQuery } from '@core/store/account/account.query';
import { Observable, from } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '@core/services/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor(private accountQuery: AccountQuery,
    private authService: AuthService) {
  }
 public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  if (req.url.includes('https://graph.microsoft.com/v1.0/me/photo/$value')) {
    // Remove the URL
    const url = req.url.replace('https://graph.microsoft.com/v1.0/me/photo/$value', '');
    // Remove the Authorization header
    const headers = req.headers.delete('Authorization');
    // Add the Authorization header with a placeholder value
    const newHeaders = headers.set('Authorization', 'Bearer {token}');
    // Clone the request with the new URL and headers
    const clonedRequest = req.clone({ url, headers: newHeaders });
    // Replace the placeholder with the actual token
    return from(this.authService.getAccessToken()).pipe(
      switchMap(token => {
        const authReq = clonedRequest.clone({
          headers: clonedRequest.headers.set('Authorization', `Bearer ${token}`)
        });
        return next.handle(authReq);
      })
    );
  } else {
    const url = req.url.replace('//', '/');
    return next.handle(req.clone({ url: `${environment.apiBaseUrl}${url}` }));
  }
}
}

export const apiUrlInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiUrlInterceptor,
  multi: true,
}

